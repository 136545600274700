<template>
  <b-form @submit.prevent="$emit('processInquilino', inquilino)">
    <!-- Sistema Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Rut -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Rut"
            label-for="rut"
          >
            <b-form-input
              id="rut"
              v-model="inquilino.rut"
              placeholder="Introduce el rut"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Password -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Contraseña"
            label-for="password"
          >
            <b-form-input
              id="password"
              v-model="inquilino.password"
              type="password"
              placeholder="Introduce la contraseña"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Nombre -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Hosting"
            label-for="hosting"
          >
            <b-form-input
              id="hosting"
              v-model="inquilino.hosting"
              placeholder="Introduce el hosting"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Nombre -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Nombres"
            label-for="nombres"
          >
            <b-form-input
              id="nombres"
              v-model="inquilino.nombres"
              placeholder="Introduce el nombres"
            />
          </b-form-group>
        </b-col>

        <!-- Field: primeroApellido -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Apellido Paterno"
            label-for="primeroApellido"
          >
            <b-form-input
              id="primeroApellido"
              v-model="inquilino.primeroApellido"
              placeholder="Introduce el apellido paterno"
            />
          </b-form-group>
        </b-col>

        <!-- Field: segundoApellido -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Apellido Materno"
            label-for="segundoApellido"
          >
            <b-form-input
              id="segundoApellido"
              v-model="inquilino.segundoApellido"
              placeholder="Introduce el apellido materno"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Correo -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Correo"
            label-for="correo"
          >
            <b-form-input
              id="correo"
              v-model="inquilino.correo"
              type="email"
              placeholder="Introduce el correo"
            />
          </b-form-group>
        </b-col>

      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <btnSubmit
      :btnText="inquilinoSubmit"
    />

  </b-form>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
// import { validationMixin } from 'vuelidate'
// import {
//   required, maxLength, email,
// } from 'vuelidate/lib/validators'
// import { formatearTelefono } from '@/mixins/forms'

import btnSubmit from '../../components/Form/btnSubmit.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,

    // vSelect,
    btnSubmit,
  },
  // mixins: [validationMixin],
  props: {
    inquilino: {
      type: Object,
      required: true,
    },
    inquilinoSubmit: {
      type: String, // Texto del boton
      default: 'Guardar Sistema',
    },
  },
  // validations: {
  //   inquilino: {
  //     rut: {
  //       required,
  //       maxLength: maxLength(50),
  //     },
  //     password: {
  //       required,
  //       maxLength: maxLength(30),
  //     },
  //     nombres: {
  //       required,
  //       maxLength: maxLength(80),
  //     },
  //     primeroApellido: {
  //       maxLength: maxLength(80),
  //     },
  //     segundoApellido: {
  //       maxLength: maxLength(80),
  //     },
  //     correo: {
  //       required,
  //       maxLength: maxLength(80),
  //       email,
  //     },
  //     estado: {},
  //   },
  // },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
