<template>
  <div>
    <b-card>
      <inquilinosForm
        :inquilino="inquilinoData"
        inquilinoSubmit="Crear Inquilino"
        @processInquilino="agregarInquilino"
      />
    </b-card>
  </div>
</template>

<script>

import { BCard } from 'bootstrap-vue'
import { mapActions } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import inquilinosForm from './components/InquilinosForm.vue'
// import inquilinosForm from './components/InquilinosForm.vue'

export default {
  components: {
    BCard,
    inquilinosForm,
  },
  data() {
    return {
      inquilinoData: {
        nombre: '',
        estado: null,
      },
    }
  },
  methods: {
    ...mapActions({ createInquilino: 'inquilinos/addInquilinos' }),
    agregarInquilino(inquilino) {
      this.createInquilino(inquilino).then(() => {
        const errorInquilinos = store.state.inquilinos
        const errorMessage = errorInquilinos.errorMessage.errors
        if (!errorInquilinos.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Inquilino creado con éxito 👍',
              icon: 'CheckIcon',
              text: `El inquilino "${inquilino.nombre}" fue creado con éxito!`,
              variant: 'success',
            },
          },
          {
            timeout: 4000,
          })
          this.$router.replace({
            name: 'inquilinos',
          })
        } else if (errorMessage.nombre) {
          this.$swal({
            title: 'Error!',
            text: `${errorMessage.nombre[0]}!`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
  },
}
</script>
